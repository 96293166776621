<template>
<div>
    <Breadcrumbs main="BNPS" submain="Activity Form" title="Response Details" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <div class="card">

                    <div class="card-body" v-if="!loading && !error">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Activity Name</h6>
                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter activity name" v-model="form.activity.activity.title" disabled>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.topics">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Topics</h6>
                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter topics" v-model="form_data.topics" disabled>
                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.program_date">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Program Date</h6>

                                                    </div>
                                                    <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="form_data.program_date" disabled></datepicker>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.event_expenditure">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Event Expenditure</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter event expenditure" v-model="form_data.event_expenditure" disabled>
                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.venue">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Venue</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="Enter venue" v-model="form_data.venue" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.activity_code">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Activity Code</h6>
                                                    </div>
                                                    <input class="form-control" type="text" placeholder="" v-model="form_data.activity_code" disabled>
                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.budget_code">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Budget Code</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="" v-model="form_data.budget_code" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row">

                                            </div> -->
                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.participants">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Participants</h6>
                                                    </div>
                                                    <div>
                                                        <!-- <div class="" style="display: flex; gap: 16px;">
                                                                <div style="width: 40%;">
                                                                    <h6>Category</h6>
                                                                </div>

                                                                <div style="width: 40%;">
                                                                    <h6>Total</h6>
                                                                </div>

                                                                <div style="width: 20%;">
                                                                    <h6>Action</h6>
                                                                </div>
                                                            </div> -->

                                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.participants" :key="index">
                                                            <div style="width: 40%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.category" type="text" placeholder="" disabled></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 40%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.total" type="number" placeholder="" disabled></b-form-input>
                                                                </div>
                                                            </div>

                                                            <!-- <div style="width: 20%; display: flex; align-items: center;">

                                                                    <svg @click="addField(input, form_data.participants)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                                        <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                    </svg>

                                                                    <svg v-show="form_data.participants.length > 1" @click="removeField(index, form_data.participants)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                                        <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                                    </svg>
                                                                </div> -->
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.professions">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Professions</h6>

                                                    </div>
                                                    <div>
                                                        <!-- <div class="" style="display: flex; gap: 16px;">
                                                                <div style="width: 45%;">
                                                                    <h6>Profession Name</h6>
                                                                </div>

                                                                <div style="width: 20%;">
                                                                    <h6>Action</h6>
                                                                </div>
                                                            </div> -->

                                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.professions" :key="index">
                                                            <div style="width: 45%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.name" type="text" placeholder="" disabled></b-form-input>
                                                                </div>
                                                            </div>

                                                            <!-- <div style="width: 20%; display: flex; align-items: center;">

                                                                    <svg @click="addProfessionsField(input, form_data.professions)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                                        <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                    </svg>

                                                                    <svg v-show="form_data.professions.length > 1" @click="removeProfessionsField(index, form_data.professions)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                                        <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                                    </svg>
                                                                </div> -->
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.monthly_income">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Monthly Income</h6>

                                                    </div>

                                                    <multiselect v-model="form_data.monthly_income" tag-placeholder="Monthly Income" placeholder="Add monthly income" label="name" :max="monthly_income_list.length" track-by="id" :options="monthly_income_list" :multiple="true" :taggable="true" disabled></multiselect>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.age_group">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Age Group</h6>

                                                    </div>
                                                    <multiselect v-model="form_data.age_group" tag-placeholder="Age Group" placeholder="Add age group" label="name" :max="age_group_list.length" track-by="id" :options="age_group_list" :multiple="true" :taggable="true" disabled></multiselect>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.program_objectives">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Program Objective</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.program_objectives" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.activity_in_brief">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Activity in brief</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.activity_in_brief" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.fac_of_the_program">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Facilitator of the Program</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="" v-model="form_data.fac_of_the_program" disabled>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.outcome">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Outcome</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.outcome" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.challenges">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Challenges</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.challenges" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6" v-if="form.learnings">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Learnings</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.learnings" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6" v-if="form.recommendations">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Recommendations</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.recommendations" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.risk_factor">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Risk factors</h6>

                                                    </div>
                                                    <input class="form-control" type="text" placeholder="" v-model="form_data.risk_factor" disabled>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12" v-if="form.remarks">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6>Remarks</h6>

                                                    </div>
                                                    <b-form-textarea v-model="form_data.remarks" rows="3" max-rows="8" placeholder="" disabled></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>
                                     
                                        
                                        <div class="row">
                                            <div class="col-sm-4" v-if="form.report_attach">
                                                <div>
                                                    <div class="d-flex justify-content-between">
                                                        <h6 class="mb-2">Report Attachments</h6>
                                                    </div>

                                                    <b-table show-empty sticky-header :items="form_data.report_attach" :fields="Attachment" head-variant="light">
                                                        <template #cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>

                                                        <template #cell(title)="data">
                                                            <a :href="host+data.item.report_attachment" target="_blank" class="link-style"> {{ data.item.attach_title.substring(0,20) }}</a>
                                                        </template>

                                                    </b-table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <loader />
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import loader from '../../loader/loader.vue'
import {
    myHost
} from "../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect,
        loader

    },

    data() {
        return {
            host: myHost.host,
            form: {
                activity: null,
                venue: true,
                activity_code: true,
                budget_code: true,
                program_date: true,
                participants: true,
                professions: true,
                monthly_income: true,
                age_group: true,
                topics: true,
                event_expenditure: true,
                program_objectives: true,
                activity_in_brief: true,
                fac_of_the_program: true,
                outcome: true,
                challenges: true,
                learnings: true,
                recommendations: true,
                risk_factor: true,
                remarks: true,
                report_attach: true,

            },
            form_data: {
                activity: null,
                venue: null,
                activity_code: null,
                budget_code: null,
                program_date: null,
                participants: [{
                    category: null,
                    total: null
                }],
                professions: [{
                    name: null,
                }],
                monthly_income: [],
                age_group: [],
                topics: null,
                event_expenditure: null,
                program_objectives: null,
                activity_in_brief: null,
                fac_of_the_program: null,
                outcome: null,
                challenges: null,
                learnings: null,
                recommendations: null,
                risk_factor: null,
                remarks: null,
                report_attach: null,
            },

            activity_locations: [{
                location_id: null,
                quantity: null,
            }],

            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: false
                },
            ],
            monthly_income_list: [{
                    id: '1',
                    name: '3000-5000',
                },
                {
                    id: '2',
                    name: '5001-7000',
                },
                {
                    id: '3',
                    name: '7001-9000',
                },
                {
                    id: '4',
                    name: '9001-11000',
                },
                {
                    id: '5',
                    name: '11001-13000',
                },
                {
                    id: '6',
                    name: '13001-15000',
                },
                {
                    id: '7',
                    name: '15000>',
                },

            ],

            age_group_list: [{
                    id: '1',
                    name: '<10',
                },
                {
                    id: '2',
                    name: '10-14',
                },
                {
                    id: '3',
                    name: '15-18',
                },
                {
                    id: '4',
                    name: '19-24',
                },
                {
                    id: '5',
                    name: '25-29',
                },
                {
                    id: '6',
                    name: '<35-50',
                },
                {
                    id: '7',
                    name: '51>',
                },

            ],
        }
    },

    computed: {
        ...mapState({
            details: state => state.bnps_project.activity_response_detail,
            loading: state => state.bnps_project.activityFormLoading,
            error: state => state.bnps_project.activityFormError,

        }),

    },

    mounted() {

        this.$store.dispatch("bnps_project/fetchActivityResponseDetails", this.$route.params.id)

    },

    watch: {

        details(newValue, oldValue) {
            this.form = this.details.form_bool

            this.form_data.activity = this.details.form_data.activity
            this.form_data.venue = this.details.form_data.venue
            this.form_data.activity_code = this.details.form_data.activity_code
            this.form_data.budget_code = this.details.form_data.budget_code
            this.form_data.program_date = this.details.form_data.program_date
            this.form_data.participants = JSON.parse(this.details.form_data.participants)
            this.form_data.professions = JSON.parse(this.details.form_data.professions)
            this.form_data.monthly_income = JSON.parse(this.details.form_data.monthly_income)
            this.form_data.age_group = JSON.parse(this.details.form_data.age_group)
            this.form_data.topics = this.details.form_data.topics
            this.form_data.event_expenditure = this.details.form_data.event_expenditure
            this.form_data.program_objectives = this.details.form_data.program_objectives
            this.form_data.activity_in_brief = this.details.form_data.activity_in_brief
            this.form_data.fac_of_the_program = this.details.form_data.fac_of_the_program
            this.form_data.outcome = this.details.form_data.outcome
            this.form_data.challenges = this.details.form_data.challenges
            this.form_data.learnings = this.details.form_data.learnings
            this.form_data.recommendations = this.details.form_data.recommendations
            this.form_data.risk_factor = this.details.form_data.risk_factor
            this.form_data.remarks = this.details.form_data.remarks
            this.form_data.report_attach = this.details.form_data.report_attach

            // this.form_data = this.details.form_data
        }
    },

    methods: {

        toggleProperty(property) {
            if (this.form.hasOwnProperty(property)) {
                this.form[property] = !this.form[property];
            }
        },

        addField(value, fieldType) {
            fieldType.push({
                category: null,
                total: null
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        addProfessionsField(value, fieldType) {
            fieldType.push({
                name: null,
            });
        },

        removeProfessionsField(index, fieldType) {
            fieldType.splice(index, 1);
        },

    }
};
</script>

<style scoped>
.low-opacity {
    opacity: 0.5;
}
</style>
