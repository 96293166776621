<template>
    <div>
        <Breadcrumbs main="BNPS" submain="Internal Activity Management" title="Project Edit" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form" v-if="!loading && !error">
    
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Project Title</label>
                                            <input class="form-control" type="text" placeholder="Enter project title *" v-model="temp_project.title">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Location</label>
                                            <multiselect v-model=" assignedLocation" tag-placeholder="Add Location" placeholder="Search location" label="name" :max="location_list.length" track-by="id" :options="location_list" :multiple="true" :taggable="true" ></multiselect>
                                        </div>
                                    </div>
    
                                </div>
    
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Priority</label>
                                            <select class="form-control digits" v-model="temp_project.priority">
                                                <option v-for="(priority, index) in priorities" :key="index">{{ priority }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select class="form-control digits" v-model="temp_project.status">
                                                <option v-for="(status, index) in statuses" :key="index">{{ status }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Starting date</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_project.start_date"></datepicker>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Deadline</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_project.deadline"></datepicker>
                                        </div>
                                    </div>
                                </div>
    
                               
    
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Project Details</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter project details..." rows="4" v-model="temp_project.details"></textarea>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Update</button>
                                            <router-link to="/internal-activity/project">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
    
                            </div>

                            <div v-else>
                                <loader/>
                            </div>
                        </div>
                    </px-card>
                </div>

                
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Datepicker from 'vuejs-datepicker';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import moment from "moment";
    import Swal from 'sweetalert2/dist/sweetalert2.js'
    import 'sweetalert2/src/sweetalert2.scss'
    import Multiselect from 'vue-multiselect';
    import loader from '../../loader/loader.vue'
    export default {
        components: {
            vueDropzone: vue2Dropzone,
            Datepicker,
            Multiselect,
            loader
        },
    
        data() {
            return {
                temp_project: {
                    title: '',
                    details: '',
                    start_date: null,
                    deadline: null,
                    priority: 'HIGH',
                    status: 'APPROVED',
                   
                },
                location:null,
    
                assignedLocation: [],
    
                priorities: ['HIGH', 'MEDIUM', 'LOW'],
                statuses: ['PENDING', 'APPROVED', 'WORKING', 'COMPLETED'],
                
            };
        },
        mounted() {
            this.$store.dispatch("location/fetchLocation")
            this.$store.dispatch("bnps_project/fetchProjectDetails", this.$route.params.id)
        },
        computed: {
            ...mapState({
                location_list: state => state.location.location,
                project_detail: state => state.bnps_project.project_detail,
                loading: state => state.bnps_project.detailLoading,
                error: state => state.bnps_project.detailError,
    
            }),
            formattedLocationOptions() {
                return this.location_list.map(location => ({
                    id: location.id,
                    user: location.name
                }));
            },
        },

        watch: {
        project_detail(oldValue, newValue) {
            this.temp_project.title = this.project_detail.title ? this.project_detail.title : null
            this.temp_project.details = this.project_detail.details ? this.project_detail.details : null
            this.temp_project.start_date = this.project_detail.start_date ? this.project_detail.start_date : null
            this.temp_project.deadline = this.project_detail.deadline ? this.project_detail.deadline : null
            this.temp_project.priority = this.project_detail.priority ? this.project_detail.priority : null
            this.temp_project.status = this.project_detail.status ? this.project_detail.status : null
            this.assignedLocation= this.project_detail.location ? this.project_detail.location : null
        }
    },
    
        methods: {
           
    
            onSubmit() {
                if (this.temp_project.start_date !== null) {
                    this.temp_project.start_date = moment(String(this.temp_project.start_date)).format("YYYY-MM-DD");
                }
                if (this.temp_project.deadline !== null) {
                    this.temp_project.deadline = moment(String(this.temp_project.deadline)).format("YYYY-MM-DD");
                }
    
             
                if (!Array.isArray(this.location)) {
                    this.location = [];
                    this.assignedLocation.map(item => this.location.push(item.id));
                }
                  
                
                this.$store.dispatch("bnps_project/updateProject", {project:this.temp_project, location:this.location, id: this.$route.params.id,}).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                            this.temp_project.title = '',
                            this.temp_project.details = '',
                            this.temp_project.start_date = null,
                            this.temp_project.deadline = null,
                            this.temp_project.priority = 'HIGH',
                            this.temp_project.status = 'APPROVED',
                            this.location = null,
                            this.assignedLocation = []
                            
                            setTimeout(() => {
                                this.$router.push('/internal-activity/project') 
                            }, 2000)
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        }
    };
    </script>
    