<template>


    <div class="loader-box">
        <div class="loader-10"></div>
    </div>


</template>

<script>


export default {
    data() {
        return {
          
        };
    },

    computed: {

    },

   

    mounted() {
      
    },

    methods: {
    
    }
};
</script>
