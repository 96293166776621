import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        project: [],
        project_detail: {},
       
        project_by_search:[],
       
        activity_location_count:[],

        submitLoading: false,
        submitError: false,

        detailLoading:false,
        detailError:false,


        activity: [],
        activity_detail: {},

        activityLoading:false,
        activityError:false,

        submitSuccess: false,


        form: [],
        responses: [],
        activity_form_list:[],

        activity_response_detail:{},
        activity_responses:[],

        activityFormLoading:false,
        activityFormError:false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_PROJECT (state, project) {
            state.project = project;
        },
        SET_PROJECT_DETAIL (state, project) {
            state.project_detail = project;
        },

        SET_PROJECT_BY_SEARCH(state, project) {
            state.project_by_search = project;
        },

        SET_ACTIVITY_LOCATION_COUNT (state, location) {
            state.activity_location_count = location;
        },
    

        PROJECT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        PROJECT_ERROR (state, hasError) {
            state.submitError = hasError;
        },
        PROJECT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        PROJECT_DETAIL_LOADING (state, loading) {
            state.detailLoading = loading;
        },
        PROJECT_DETIAL_ERROR (state, hasError) {
            state.detailError = hasError;
        },

        SET_ACTIVITY (state, activity) {
            state.activity = activity;
        },
        SET_ACTIVITY_DETAIL (state, activity) {
            state.activity_detail = activity;
        },


        ACTIVITY_LOADING (state, loading) {
            state.activityLoading = loading;
        },
        ACTIVITY_ERROR (state, hasError) {
            state.activityError = hasError;
        },

      



        //Activity Form
        SET_ACTIVITY_FORM (state, item) {
            state.form = item;
        },

        SET_ACTIVITY_RESPONSE_DETAILS(state, detail){
            state.activity_response_detail = detail
        },
        SET_ACTIVITY_RESPONSES(state, responses){
            state.activity_responses = responses
        },

        ACTIVITY_FORM_LOADING (state, loading) {
            state.activityFormLoading = loading;
        },
        ACTIVITY_FORM_ERROR (state, hasError) {
            state.activityFormError = hasError;
        },


     
    },

    actions: {

        async checkMyPassword({commit,dispatch}, payload) {
         
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.check_my_password, {password:payload.password}, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('PROJECT_SUCCESS', true);
               
                return response

            }catch (e) {
                
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async addProject ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.project);

                if(payload.location !== null) {
                    payload.location.forEach((item) => data.append("location", item))
                }
                
                let response = await axios.post(V1API.create_project_bnps, payloadData, config).then(result=>{
                    // let data = decode(result.data)
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchProject')
                return response

            }catch (e) {
               
                commit('PROJECT_ERROR', true);
            }finally {
               
                commit('PROJECT_LOADING', false);
            }
        },


        async updateProject ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  },
                  "Content-Type": "multipart/form-data"
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {

                    let data= new FormData()
                    let payloadData = buildFormData(data, payload.project);

                    if(payload.location !== null) {
                        payload.location.forEach((item) => data.append("location", item))
                    }

                    response= await axios.patch(V1API.edit_project_bnps+payload.id,payloadData,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    // dispatch('fetchProject');

                }
            )
            return response
        },

        async deleteProject ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_bnps_project + payload, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchProject')
            
            return response
        },

        async fetchProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{

                if(!payload){
                    let result = await axios.get(V1API.get_project_bnps, config);
                    let response = result.data
                    commit('SET_PROJECT', response.data);
                }
                if(payload){
                    let result = await axios.get(V1API.get_project_bnps+'?project_status='+payload, config);
                    let response = result.data
                    commit('SET_PROJECT', response.data);
                }
                

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }

        
        },

        async fetchProjectDetails ({ commit }, payload) {
            commit('PROJECT_DETAIL_LOADING', true);
            commit('PROJECT_DETIAL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_bnps_project_detail + payload, config);
                let response = result.data

                commit('SET_PROJECT_DETAIL', response.data);

            } catch(error) {
                commit('PROJECT_DETIAL_ERROR', true);
            }
            finally {
                commit('PROJECT_DETAIL_LOADING', false);
            }
        },


        async fetchActivityLocationsCountByProject ({ commit }, payload) {
            commit('PROJECT_DETAIL_LOADING', true);
            commit('PROJECT_DETIAL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_activity_location_count_by_project + payload, config);
                let response = result.data

                commit('SET_ACTIVITY_LOCATION_COUNT', response.data);

            } catch(error) {
                commit('PROJECT_DETIAL_ERROR', true);
            }
            finally {
                commit('PROJECT_DETAIL_LOADING', false);
            }
        },


        //Activity
        async fetchActivity ({ commit },payload) {
            commit('ACTIVITY_LOADING', true);
            commit('ACTIVITY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                if(payload.by_location){
                    let result = await axios.get(V1API.activity_list_by_project+payload.project_id + '?location_id=' + payload.location_id, config);
                    let items = result.data.data
                    commit('SET_ACTIVITY', items);
                }
                else{
                    let result = await axios.get(V1API.activity_list_by_project+payload.project_id, config);
                    let items = result.data.data
                    commit('SET_ACTIVITY', items);
                }

            } catch(error) {
                commit('ACTIVITY_ERROR', true);
            }
            finally {
                commit('ACTIVITY_LOADING', false);
            }
           
        },

        async fetchActivityDetails ({ commit },payload) {
            commit('ACTIVITY_LOADING', true);
            commit('ACTIVITY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.activity_details+payload, config);
                let items = result.data.data

                commit('SET_ACTIVITY_DETAIL', items);

            } catch(error) {
                commit('ACTIVITY_ERROR', true);
            }
            finally {
                commit('ACTIVITY_LOADING', false);
            }
           
        },


        async addActivity ({commit, dispatch}, payload) {
            commit('ACTIVITY_LOADING', true);
            commit('ACTIVITY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_activity, payload.activity, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchActivity',{project_id:payload.project_id} )
                return response

            }catch (e) {
                commit('ACTIVITY_ERROR', true);
            }finally {
                commit('ACTIVITY_LOADING', false);
            }
        },

        async updateActivity ({commit, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response = await axios.patch(V1API.edit_activity+ payload.id, payload.activity, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch ('fetchActivity',{project_id:payload.project_id})
                }
                
            )
            return response
        },

        async deleteActivity ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_activity + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchActivity',{project_id:payload.project_id});

            return response
        },


        //Activity Form
        async fetchForm ({ commit }, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_activity_form + payload, config);
                let response = result.data

                commit('SET_ACTIVITY_FORM', response.data);
                return response

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }
            finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },

        async addForm ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                
            };

            try{
                let response = await axios.post(V1API.create_activity_form + payload.location, payload.form, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchForm', payload.policy)
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },
        
        async submitActivityForm ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };


            try{


                payload.form_data.participants=JSON.stringify(payload.form_data.participants)
                payload.form_data.professions=JSON.stringify(payload.form_data.professions)
                payload.form_data.monthly_income=JSON.stringify(payload.form_data.monthly_income)
                payload.form_data.age_group=JSON.stringify(payload.form_data.age_group)
             

                let data= new FormData()
                let payloadData = buildFormData(data, payload.form_data);

                if(payload.attach !== null) {
                    payload.attach.forEach((item) => data.append("report_attach", item))
                }
                
                let response = await axios.post(V1API.activity_form_submit_create, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchForm', payload.policy)
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },


        async fetchActivityFormRespones ({ commit },payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{

                let result = await axios.get(V1API.get_activity_form_responses+payload, config);
                let response = result.data
                commit('SET_ACTIVITY_RESPONSES', response.data);   

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }

        
        },

        async fetchActivityResponseDetails ({ commit }, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.activity_form_response_details + payload, config);
                let response = result.data

                commit('SET_ACTIVITY_RESPONSE_DETAILS', response.data);

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }
            finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },


        async deleteActivityResponse ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_activity_form_responses + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchActivityFormRespones',payload.form_id)
            
            return response
        },

        
        

       

    },

    getters: {}
}
