<template>
    <div>
        <Breadcrumbs main="BNPS" submain="Internal Activity Management" title="Location" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
    
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Location</h7>
                            </button>
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
    
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div v-if="!loading && !error">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="location_list" :fields="tablefields" :current-page="currentPage" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>
    
                                        <template #cell(status)="data">
                                            <span v-if="data.item.status == true" @click="updateTrainingTypeStatus(data.item.id)" style="cursor: pointer;">
                                                <b-badge pill variant="success">Active</b-badge>
                                            </span>
                                            <span v-if="data.item.status == false" @click="updateTrainingTypeStatus(data.item.id)" style="cursor: pointer;">
                                                <b-badge pill variant="danger">Inactive</b-badge>
                                            </span>
                                        </template>
    
                                        <template #cell(description)="data">
                                            <span>
                                                <div>{{ data.item.description.substring(0, 100) }}</div>
                                            </span>
                                        </template>
    
                                        <template #cell(action)="data">
                                            <span style="display: flex; gap: 12px;">
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteLocation(data.item.id)" title="Delete"></feather>
                                                <div @click="editLocation(data.item)">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                                </div>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <div v-else>
                                <div class="loader-box">
                                    <div class="loader-10"></div>
                                </div>
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Location Create Modal -->
        <b-modal id="modal-prevent-closing" ref="modal" title="Add Location" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Name" label-for="name-input">
                    <b-form-input v-model="location.name" placeholder="Enter location name"></b-form-input>
                </b-form-group>
    
               
            </form>
        </b-modal>
    
        <!-- Training Type Edit Modal -->
        <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Location" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group label="Name" label-for="name-input">
                    <b-form-input v-model="edit_location.name" placeholder="Enter location name"></b-form-input>
                </b-form-group>
    
               
            </form>
        </b-modal>
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this location?</div>
        </b-modal>
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    
    export default {
        data() {
            return {
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'name',
                        label: 'Title',
                        sortable: true
                    },
                  
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false
                    }
                ],
    
                statusOptions: [{
                        text: 'Active',
                        value: true
                    },
                    {
                        text: 'Inactive',
                        value: false
                    }
                ],
    
                location: {
                    name: '',
                    
                },
    
                edit_location: {
                    id: '',
                    name:'',
                   
                },
    
                delete_value: null,
                udate_value: null,
    
                filter: null,
    
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15],
    
                temp_quotation: {}
            };
        },
    
        computed: {
            ...mapState({
                location_list: state => state.location.location,
                loading: state => state.location.locationLoading,
                error: state => state.location.locationError,
            }),
            sortOptions() {
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
    
            this.$store.dispatch("location/fetchLocation", {
            }).then(() => {
                this.items = this.location_list;
                this.totalRows = this.items.length;
               
            });
    
        },
        watch: {
    
           
        },
    
        methods: {
            onFiltered(filteredItems) {
    
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            resetModal() {
                this.location.name = null
            },
    
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
    
            handleSubmit() {
                this.$store.dispatch("location/addLocation", this.location).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                            this.location.name = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            },
    
            editLocation(type) {
                this.edit_location.id = type.id
                this.edit_location.name = type.name
                
            },
    
            resetEditModal() {
                this.edit_location.id = null
                this.edit_location.name = null
            },
    
            handleEditOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleEditSubmit()
            },
    
            handleEditSubmit() {
                this.$store.dispatch("location/updateLocation", {
                    id: this.edit_location.id,
                    location: this.edit_location
                }).then(response => {
    
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        
                        this.edit_location.id = null
                        this.edit_location.name = null
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-edit')
                })
            },
            deleteLocation(id) {
                this.delete_value = id
            },
            handleDeleteOk() {
                this.$store.dispatch("location/deleteLocation", this.delete_value).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
          
        }
    };
    </script>
    