import { render, staticRenderFns } from "./response_details.vue?vue&type=template&id=38868242&scoped=true&"
import script from "./response_details.vue?vue&type=script&lang=js&"
export * from "./response_details.vue?vue&type=script&lang=js&"
import style0 from "./response_details.vue?vue&type=style&index=0&id=38868242&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38868242",
  null
  
)

export default component.exports