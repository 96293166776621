<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Payments" title="Requisition Payments" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                       
                        <div class="table-responsive datatable-vue" v-if="!submitLoading && !submitError">
                            <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(requisition)="data">
                                    <span v-if="data.item.requisition">
                                        <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.requisition.id}`" class="link-style">
                                            {{ data.item.requisition.title}}
                                        </router-link>
                                    </span>
                                </template>

                                <template #cell(requested_by)="data">
                                    <span v-if="data.item.requested_by">
                                        <!-- <router-link :to="`/hr/organization/employee/details/${data.item.requested_by.id}`"> -->
                                        {{ data.item.requested_by.first_name}} {{ data.item.requested_by.last_name}}
                                        <!-- </router-link> -->
                                    </span>
                                </template>

                                <template #cell(approved_by)="data">
                                    <span v-if="data.item.approved_by">
                                        <!-- <router-link :to="`/hr/organization/employee/details/${data.item.approved_by.id}`"> -->
                                        {{ data.item.approved_by.first_name }} {{ data.item.approved_by.last_name }}
                                        <!-- </router-link> -->
                                    </span>
                                </template>

                                <!-- <template #cell(refer_to)="data">
                                        <span>
                                            <div v-if="data.item.refer_to !== null && data.item.refer_to.length > 0">
                                                <div v-for="user in data.item.refer_to" :key="user.id">
                                                    <p>{{ user.first_name }}</p>
                                                </div>
                                            </div>
                                        </span>
                                    </template> -->

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'APPROVED'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.item, row.item.id)" v-if="row.value == 'PENDING'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="primary" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.item, row.item.id)" v-if="row.value == 'REJECTED'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="danger" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span>
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequest(data.item.id)" title="Delete"></feather>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Payment Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Status Approved Modal -->
    <!-- <b-modal id="modal-center-approved" centered hide-header @ok="handlePayementSubmit">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to approve this payment?</div>
        </b-modal>
     -->
    <!-- Leave Request Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleRequestDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this payment?</div>
    </b-modal>

    <!-- Payment Approve -->
    <b-modal id="modal-prevent-status" ref="modal" title="Add Transaction" size="xl" hide-footer>
        <paymentApproveVue :amount="this.approved_status" :paymentType="'REQUISITION'" />
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import {
    myHost
} from '../../../../_helpers/host';
import paymentApproveVue from './payment-approve.vue';

export default {
    components: {
        Datepicker,
        Multiselect,
        paymentApproveVue
    },

    data() {
        return {
            host: myHost.host,
            items: [],
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'requisition',
                    label: 'Requisition',
                    sortable: true
                },
                {
                    key: 'requisition',
                    label: 'Requisition',
                    sortable: true
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true
                },
                {
                    key: 'paid_amount',
                    label: 'Paid Amount',
                    sortable: true
                },
                {
                    key: 'requested_by',
                    label: 'Requested By',
                    sortable: true
                },
                {
                    key: 'approved_by',
                    label: 'Approved By',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            approved_status: {
                update_id: '',
                amount: null,
            },

            statuses: ['PENDING', 'APPROVED', 'REJECTED'],
            selectedStatus: '',

            filter: null,
            temp_request_id: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState('payments', ["requisition_payments", "submitError", "submitSuccess", "submitLoading"]),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    created() {
        this.$store.dispatch('payments/fetchRequisitionPayments').then(() => {
            this.items = this.requisition_payments
            this.totalRows = this.items.length;
        })
    },

    watch: {
        requisition_payments(oldValue, newValue) {
            this.items = this.requisition_payments
        }
    },

    mounted() {
        this.totalRows = this.items.length;
    },

    methods: {
        deleteRequest(id) {
            this.temp_request_id = id
        },

        handleRequestDeleteOk() {
            this.$store.dispatch("payments/deleteRequisitionPayments", this.temp_request_id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.temp_request_id = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getStatus(status, id) {
            this.approved_status.amount = status.paid_amount
            this.approved_status.update_id = id
            this.selectedStatus = status.status
        },

        resetModal() {
            this.selectedStatus = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            if (this.selectedStatus === 'APPROVED') {
                this.$nextTick(() => {
                    this.$bvModal.show('modal-prevent-status')
                })
            } else {
                this.$store.dispatch("payments/updateRequisitionPaymentStatus", {
                    id: this.approved_status.update_id,
                    status: this.selectedStatus
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.update_id = null

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            }
        },

        // handlePayementSubmit() {
        //     this.$store.dispatch("payments/updateRequisitionPaymentStatus", {
        //         id: this.update_id,
        //         status: 'APPROVED'
        //     }).then(response => {
        //         if (response.code == 200) {
        //             Swal.fire({
        //                 title: "Success!",
        //                 text: response.response,
        //                 icon: "success"
        //             });

        //             this.update_id = null

        //         } else {
        //             Swal.fire({
        //                 title: "Error!",
        //                 text: response.response,
        //                 icon: "error"
        //             });
        //         }
        //     })

        //     this.$nextTick(() => {
        //         this.$bvModal.hide('modal-prevent-closing')
        //     })
        // }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
