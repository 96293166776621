<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Requisition" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header header-mobile">
                        <router-link to="/finance-and-accounts/account/requisition/create/new">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Requisition</h7>
                                </div>
                            </button>
                        </router-link>
                        <router-link to="/finance-and-accounts/account/requisition/type">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-solid fa-bars"></i>
                                <h7>Requisition Type</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(requisition_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`" class="link-style">
                                                {{ data.item.requisition_id }}
                                            </router-link>
                                        </span>
                                    </template>
                                    <template #cell(title)="data">
                                        <span>

                                            {{ data.item.title }}

                                        </span>
                                    </template>

                                    <template #cell(raised_by)="data">
                                        <div class="d-flex flex-column">
                                            <span>
                                                <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`" class="link-style">
                                                    {{ data.item.raised_by.first_name }} {{ data.item.raised_by.last_name }}
                                                </router-link>
                                            </span>
                                            <span>
                                                {{ formattedDate(data.item.created_at) }}
                                            </span>
                                        </div>
                                    </template>

                                    <template #cell(grand_total)="data">
                                        <!-- <span>
                                        {{ data.item.grand_total }}
                                    </span> -->
                                        <div class="d-flex flex-column" style="width: 150px;">

                                            <span class="font-weight-bold">Total: {{ data.item.grand_total }}</span>
                                            <span><span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }}</span>
                                            <span><span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }}</span>
                                        </div>
                                    </template>

                                    <template #cell(refering_to)="data">
                                        <span>
                                            <div v-if="data.item.refering_to !== null">
                                                <div v-for="user in data.item.refering_to" :key="user.id">
                                                    <p>{{ user.first_name }} {{ user.last_name }}</p>
                                                </div>
                                            </div>
                                        </span>
                                    </template>
                                    <template #cell(status)="row">
                                        <!-- v-b-modal.modal-prevent-closing pill -->
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge  variant="success" v-b-modal.modal-prevent-closing pill>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                            <b-badge  variant="primary" v-b-modal.modal-prevent-closing pill>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                            <b-badge  variant="danger" v-b-modal.modal-prevent-closing pill>{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(record)="data">

                                        <div @click="recordPayment(data)">
                                            <b-badge variant="primary" v-if="data.item.due_amount > 0" v-b-modal.modal-record>Record Payment</b-badge>
                                        </div>

                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequisition(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`">
                                                <feather type="eye" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="View"></feather>
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Requisition Status -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Requisition Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Requisition Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this requisition?</div>
    </b-modal>

    <!-- Record Payment -->
    <b-modal id="modal-record" ref="modal" title="Record Payment" @show="resetModal" @hidden="resetModal" @ok="handleRecordOk">
        <form ref="form" @submit.stop.prevent="handleRecordSubmit">
            <b-form-group label="Amount" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="record.paid_amount" placeholder="Enter Amount"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'requisition_id',
                    label: 'REQ-ID',
                    sortable: true
                },
                {
                    key: 'raised_by',
                    label: 'Requested By',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'grand_total',
                    label: 'Amount',
                    sortable: true
                },

                {
                    key: 'refering_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },
                {
                    key: 'record',
                    label: 'Record',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            record: {
                paid_amount: '',
                requisition: '',
            },
            requisition_due: '',

            filter: null,
            delete_value: null,
            selectedStatus: null,
            update_id: null,
            statuses: ['DUE', 'PENDING', 'APPROVED'],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.requisition.requisition,
            count: state => state.requisition.requisition_count,
            loading: state => state.requisition.submitLoading,
            error: state => state.requisition.submitError,

        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/finance-and-accounts/account/requisition/list',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                },
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: this.perPage,
                currentPage: newCurrentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
        perPage(newperPage) {
            this.$router.replace({
                path: '/finance-and-accounts/account/requisition/list',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                },
            });
            this.perPage = newperPage
            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: newperPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        },
    },

    mounted() {
        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });

            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: this.perPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("requisition/fetchRequisition", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,

            }).then(() => {
                this.totalRows = this.count;
            });

        }

    },

    methods: {
        formattedDate(dateString) {
         
            return moment(dateString).format('YYYY-MM-DD');
        },
        recordPayment(data) {
            this.record.requisition = data.item.id
            this.requisition_due = data.item.due_amount
        },
        resetModal() {
            this.record.paid_amount = ''
        },

        handleRecordOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRecordSubmit()
        },

        handleRecordSubmit() {
            if (this.record.paid_amount > this.requisition_due) {
                Swal.fire({
                    title: "Error!",
                    text: 'Request amount is more than due amount!',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("requisition/recordPayment", this.record).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                        this.record.paid_amount = '',
                            this.record.requisition = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-record')
                })
            }

        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteRequisition(id) {
            this.delete_value = id
        },
        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status
            console.log(status, id)
        },
        resetModal() {

        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {

            this.$store.dispatch("requisition/updateRequisitionStatus", {
                id: this.update_id,
                status: this.selectedStatus,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handleDeleteOk() {
            this.$store.dispatch("requisition/deleteRequisition", {
                delete: this.delete_value,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
