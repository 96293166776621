<template>
<div>
    <Breadcrumbs main="Project" title="Projects" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="row m-0">
                        <div class="col-sm-12 p-0">
                            <b-tabs content-class="mt-3" v-model="tabIndex"  @click="categoryType('ALL')">
                                <b-tab title="All" :title-link-class="linkClass(0)">
                                    <template v-slot:title>
                                        <div>
                                            <feather type="target" class="tab-title"></feather> All
                                        </div>

                                    </template>
                                    <b-card-text>

                                        <div class="col-sm-12 p-0">

                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="row" v-if="projects.length>0">
                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/internal-activity/my-project-details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <!-- <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                            <router-link :to="{ path: '/internal-activity/edit-project/' + project.id }">
                                                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                            </router-link>
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                        </div> -->
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <!-- <div class="mt-3 row details">
                                                                            <div class="col-6">
                                                                                <span>Tasks</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                            <div class="col-6">
                                                                                <span>Resolved</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                            <div class="col-6">
                                                                                <span>Remaining</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                        </div>

                                                                        <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                            <ul v-for="(team, index) in project.teammates" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div class="project-status mt-3">
                                                                            <div class="media mb-0">
                                                                                <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                                <div class="media-body text-right">
                                                                                    <span>Done</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress" style="height: 5px">
                                                                                <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':   projectDoneTaskPercentage(project)+ '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div> -->
                                                            </div>
                                                        </div>

                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/internal-activity/my-project-details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>

                                                                    <!-- <template #cell(action)="data">
                                                                            <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                                <router-link :to="{ path: '/internal-activity/edit-project/' + data.item.id }">
                                                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                                </router-link>
                                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(data.item.id)" title="Delete"></feather>
                                                                            </div>
                                                                        </template> -->

                                                                    <!-- <template #cell(teammember)="data">
                                                                                <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                                    <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </template>

                                                                            <template #cell(task)="data">
                                                                                <div class="d-flex flex-column">
                                                                                    <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                                    <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                                    <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                                </div>
                                                                            </template> -->

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Approved" :title-link-class="linkClass(1)"  @click="categoryType('APPROVED')">
                                    <template v-slot:title>
                                        <div>
                                            <feather type="disc"></feather> Approved
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">

                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/internal-activity/my-project-details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <!-- <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                            <router-link :to="{ path: '/internal-activity/edit-project/' + project.id }">
                                                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                            </router-link>
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                        </div> -->
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <!-- <div class="mt-3 row details">
                                                                            <div class="col-6">
                                                                                <span>Tasks</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                            <div class="col-6">
                                                                                <span>Resolved</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                            <div class="col-6">
                                                                                <span>Remaining</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                        </div>

                                                                        <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                            <ul v-for="(team, index) in project.teammates" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div class="project-status mt-3">
                                                                            <div class="media mb-0">
                                                                                <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                                <div class="media-body text-right">
                                                                                    <span>Done</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress" style="height: 5px">
                                                                                <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div> -->
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/internal-activity/my-project-details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>
                                                                    <!-- <template #cell(action)="data">
                                                                            <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                                <router-link :to="{ path: '/internal-activity/edit-project/' + data.item.id }">
                                                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                                </router-link>
                                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(data.item.id)" title="Delete"></feather>
                                                                            </div>
                                                                        </template> -->

                                                                    <!-- <template #cell(teammember)="data">
                                                                                <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                                    <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </template>

                                                                            <template #cell(task)="data">
                                                                                <div class="d-flex flex-column">
                                                                                    <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                                    <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                                    <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                                </div>

                                                                            </template>
                                                                       -->

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Pending" :title-link-class="linkClass(2)"  @click="categoryType('PENDING')">
                                    <template v-slot:title>
                                        <div>
                                            <feather type="info"></feather> Pending
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">

                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/internal-activity/my-project-details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <!-- <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                            <router-link :to="{ path: '/internal-activity/edit-project/' + project.id }">
                                                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                            </router-link>
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                        </div> -->
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <!-- <div class="mt-3 row details">
                                                                            <div class="col-6">
                                                                                <span>Tasks</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                            <div class="col-6">
                                                                                <span>Resolved</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                            <div class="col-6">
                                                                                <span>Remaining</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                        </div>

                                                                        <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                            <ul v-for="(team, index) in project.teammates" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div class="project-status mt-3">
                                                                            <div class="media mb-0">
                                                                                <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                                <div class="media-body text-right">
                                                                                    <span>Done</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress" style="height: 5px">
                                                                                <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div> -->
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/internal-activity/my-project-details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>
                                                                    <!-- <template #cell(action)="data">
                                                                            <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                                <router-link :to="{ path: '/internal-activity/edit-project/' + data.item.id }">
                                                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                                </router-link>
                                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(data.item.id)" title="Delete"></feather>
                                                                            </div>
                                                                        </template> -->

                                                                    <!-- <template #cell(teammember)="data">
                                                                                <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                                    <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </template>

                                                                            <template #cell(task)="data">
                                                                                <div class="d-flex flex-column">
                                                                                    <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                                    <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                                    <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                                </div>

                                                                            </template> -->

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Working" :title-link-class="linkClass(3)" @click="categoryType('WORKING')">
                                    <template v-slot:title>
                                        <div >
                                            <feather type="compass"></feather> Working
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">

                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/internal-activity/my-project-details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <!-- <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                            <router-link :to="{ path: '/internal-activity/edit-project/' + project.id }">
                                                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                            </router-link>
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                        </div> -->
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <!-- <div class="mt-3 row details">
                                                                            <div class="col-6">
                                                                                <span>Tasks</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                            <div class="col-6">
                                                                                <span>Resolved</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                            <div class="col-6">
                                                                                <span>Remaining</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                        </div>

                                                                        <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                            <ul v-for="(team, index) in project.teammates" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div class="project-status mt-3">
                                                                            <div class="media mb-0">
                                                                                <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                                <div class="media-body text-right">
                                                                                    <span>Done</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress" style="height: 5px">
                                                                                <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div> -->
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/internal-activity/my-project-details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>
                                                                    <!-- <template #cell(action)="data">
                                                                            <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                                <router-link :to="{ path: '/project/edit/' + data.item.id }">
                                                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                                </router-link>
                                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(data.item.id)" title="Delete"></feather>
                                                                            </div>
                                                                        </template> -->

                                                                    <!-- <template #cell(teammember)="data">
                                                                                <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                                    <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </template>

                                                                            <template #cell(task)="data">
                                                                                <div class="d-flex flex-column">
                                                                                    <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                                    <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                                    <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                                </div>

                                                                            </template>
                                                                        -->

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Completed" :title-link-class="linkClass(4)" @click="categoryType('COMPLETED')">
                                    <template v-slot:title>
                                        <div >
                                            <feather type="compass"></feather> Completed
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card" v-if="!loading && !error">
                                                <div class="card-body">
                                                    <div class="row mb-4" v-if="projects.length>0">
                                                        <div style="display: flex; flex-direction: row; justify-content: space-between" class="col-xl-12">
                                                            <b-input-group class="col-xl-4">
                                                                <b-form-input v-model="query" placeholder="Type to Search"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="findProjects()">Search</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div style="display: flex; gap:15px">
                                                                <feather type="list" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="List View" @click="toggleGrid"> </feather>
                                                                <feather type="grid" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Grid View" @click="toggleList"> </feather>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" v-if="projects.length>0">

                                                        <div v-if="showGrid" class="col-xl-4 col-lg-6" v-for="(project, index) in projects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/internal-activity/my-project-details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <!-- <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                            <router-link :to="{ path: '/internal-activity/edit-project/' + project.id }">
                                                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                            </router-link>
                                                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                        </div> -->
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <!-- <div class="mt-3 row details">
                                                                            <div class="col-6">
                                                                                <span>Tasks</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                            <div class="col-6">
                                                                                <span>Resolved</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                            <div class="col-6">
                                                                                <span>Remaining</span>
                                                                            </div>
                                                                            <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                            <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                        </div>

                                                                        <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                            <ul v-for="(team, index) in project.teammates" :key="index">
                                                                                <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                    <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <div class="project-status mt-3">
                                                                            <div class="media mb-0">
                                                                                <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                                <div class="media-body text-right">
                                                                                    <span>Done</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress" style="height: 5px">
                                                                                <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div> -->
                                                            </div>
                                                        </div>
                                                        <div v-if="showList" class="col-xl-12">
                                                            <b-row>
                                                                <b-col xl="12">
                                                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>

                                                            <div class="table-responsive datatable-vue">
                                                                <b-table show-empty stacked="md" :items="projects" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                                                    <template #cell(index)="data">
                                                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                                                    </template>

                                                                    <template #cell(title)="data">
                                                                        <span style="display: flex; flex-direction: column; gap: 8px;">
                                                                            <router-link :to="{ path: '/internal-activity/my-project-details/' + data.item.id }" class="link-style" style="font-size:14px">{{data.item.title}}</router-link>
                                                                            <small><span style="font-size: medium;">
                                                                                    <span v-if="data.item.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'WORKING'" class="badge" :class="'badge-info'">{{ data.item.status }}</span>
                                                                                    <span v-if="data.item.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ data.item.status }}</span>
                                                                                </span>
                                                                            </small>

                                                                        </span>
                                                                    </template>

                                                                    <template #cell(date)="data">
                                                                        <div class="d-flex flex-column">
                                                                            <span>Start Date:</span>
                                                                            <div class="badge badge-primary" style="max-width:fit-content">{{ data.item.start_date }}</div>
                                                                            <span>Deadline:</span>
                                                                            <div class="badge badge-danger" style="max-width:fit-content">{{ data.item.deadline }}</div>

                                                                        </div>

                                                                    </template>
                                                                    <!-- <template #cell(action)="data">
                                                                            <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                                <router-link :to="{ path: '/internal-activity/edit-project/' + data.item.id }">
                                                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                                </router-link>
                                                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(data.item.id)" title="Delete"></feather>
                                                                            </div>
                                                                        </template> -->

                                                                    <!-- <template #cell(teammember)="data">
                                                                                <div class="customers mt-1 " v-if="data.item.teammates.length > 0" style="display: grid; grid-template-columns: repeat(5, minmax(0, 1fr)); gap:5px">
                                                                                    <ul v-for="(team, index) in data.item.teammates.slice(0,15)" :key="index">
                                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </template>

                                                                            <template #cell(task)="data">
                                                                                <div class="d-flex flex-column">
                                                                                    <div style="color: #1c1c1c;"> Task: {{ data.item.task }}</div>
                                                                                    <div style="color: #1c1c1c;">Resolved: {{ data.item.resolved }}</div>
                                                                                    <div style="color: #1c1c1c;">Remaining: {{ data.item.remaining }}</div>
                                                                                </div>

                                                                            </template> -->

                                                                </b-table>
                                                            </div>

                                                            <b-col md="6" class="my-1 p-0">
                                                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                                                            </b-col>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </div>

                        <!-- <div class="col-sm-4 create-project-btn">

                                <div class="text-right">
                                    <div class="d-flex align-items-center justify-content-end">

                                        <router-link to="create-project">
                                            <a class="btn btn-primary" href="">
                                                <i class="fa fa-light fa-plus" style="margin-right: 10px;"></i>
                                                <h7>Add New Project</h7>
                                            </a>
                                        </router-link>
                                    </div>
                                </div>
                            </div> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- Project Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this project?</div>
    </b-modal>

    <!-- Password Priority -->
    <b-modal id="modal-center-password" centered ref="modal" title="Enter your password to continue" @ok="handlePasswordOk">
        <form ref="form" @submit.stop.prevent="handlePasswordSubmit">
            <b-form-group label="Password" label-for="name-input">

                <div style="position: relative; width: 100%;">
                    <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                        <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </div>
                    <input :type="passwordFieldType" v-model="password" name="password" placeholder="Enter your password" class="form-control" />
                </div>

            </b-form-group>
        </form>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../_helpers/host";
export default {
    data() {
        return {

            showGrid: true,
            showList: false,
            host: myHost.host,
            tabIndex: 0,
            delete_value: '',
            password: '',
            passwordFieldType: 'password',
            query: null,
            activeItem: 'ALL',
            showSearch: false,
            projects: [],
            clientList: [],
            tablefields: [{
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'title',
                    label: 'Project',
                    sortable: true
                },
                {
                    key: 'date',
                    label: 'Start & End Date',
                    sortable: true
                },
                // {
                //     key: 'action',
                //     label: 'Action',
                //     sortable: true
                // },

                // {
                //     key: 'teammember',
                //     label: 'Team Members',
                //     sortable: true
                // },
                // {
                //     key: 'task',
                //     label: 'Task',
                //     sortable: true
                // },

            ],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        }
    },

    watch: {
        allprojects(newValue, oldValue) {
            this.projects = this.allprojects
            this.totalRows = this.projects.length;
        },
        searchedProjects(newValue, oldValue) {
            this.projects = this.searchedProjects
        },

    },

    computed: {
        ...mapState({
            allprojects: state => state.bnps_project.project,
            loading: state => state.bnps_project.submitLoading,
            error: state => state.bnps_project.submitError,
            searchedProjects: state => state.project.project_by_search,

            // doingprojects: state => state.common.doingprojects,
            // doneprojects: state => state.common.doneprojects
        }),

        projectDoneTaskPercentage() {
            return (project) => {
                if (project.task == 0) {
                    const percentage = 0;
                    return percentage
                } else {
                    const percentage = ((project.task - project.remaining) / project.task) * 100;
                    return Math.floor(percentage);
                }

            };
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        },

    },
    mounted() {
        this.$store.dispatch("bnps_project/fetchProject").then(response => {
            if (response.code == 404) {
                this.projects = []
            } else if (response.code == 200) {
                this.projects = response.data
            }
        })
    },
    methods: {

        clientInformation(type) {
            this.clientList = type.client_name
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        toggleList() {
            this.showList = false;
            this.showGrid = true;
        },
        toggleGrid() {
            this.showGrid = false;
            this.showList = true;
        },
        clearSearch() {
            this.query = null
        },
        toggleSearch() {
            this.showSearch = !this.showSearch;
        },
        findProjects() {
            this.$store.dispatch('project/fetchProjectbySearch', {
                project_status: this.activeItem,
                search_term: this.query,
            });
        },
        categoryType(item) {
            if (this.query !== null) {
                this.query = null
            }
            this.activeItem = item
            if (item === 'ALL') {
                this.$store.dispatch("bnps_project/fetchProject").then(response => {
                    if (response.code == 404) {
                        this.projects = []
                    } else if (response.code == 200) {
                        this.projects = response.data
                    }
                })
            } else {
                this.$store.dispatch("bnps_project/fetchProject", item).then(response => {
                    if (response.code == 404) {
                        this.projects = []
                    } else if (response.code == 200) {
                        this.projects = response.data
                    }
                })
            }

        },
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        deleteProject(id) {
            this.delete_value = id
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-success']
            } else {

            }
        },
        handleDeleteOk() {
            this.$store.dispatch("bnps_project/deleteProject", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        },
        handlePasswordOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handlePasswordSubmit()
        },

        handlePasswordSubmit() {
            this.$store.dispatch("bnps_project/checkMyPassword", {
                password: this.password
            }).then(response => {
                if (response.code == 200) {
                    this.$nextTick(() => {
                        this.$bvModal.show('modal-center')
                    })
                    this.password = ''

                } else {
                    this.password = ''
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-password')
            })
        },

    }
}
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}

.box {
    inline-size: 280px;
    overflow-wrap: break-word;
}

@media screen and (max-width: 1645px) {
    .box {
        inline-size: 250px;
    }
}

@media screen and (max-width: 1555px) {
    .box {
        inline-size: 230px;
    }
}

@media screen and (max-width: 1495px) {
    .box {
        inline-size: 210px;
    }
}

@media screen and (max-width: 1495px) {
    .box {
        inline-size: 190px;
    }
}

@media screen and (max-width: 1335px) {
    .box {
        inline-size: 170px;
    }
}

@media screen and (max-width: 1265px) {
    .box {
        inline-size: 150px;
    }
}

@media screen and (max-width: 1200px) {
    .box {
        inline-size: 250px;
    }
}

@media screen and (max-width: 991px) {
    .box {
        inline-size: 550px;
    }
}

@media screen and (max-width: 740px) {
    .box {
        inline-size: 400px;
    }
}

@media screen and (max-width: 590px) {
    .box {
        inline-size: 350px;
    }
}

@media screen and (max-width: 510px) {
    .box {
        inline-size: 300px;
    }
}

@media screen and (max-width: 460px) {
    .box {
        inline-size: 270px;
    }
}

@media screen and (max-width: 430px) {
    .box {
        inline-size: 240px;
    }
}

@media screen and (max-width: 400px) {
    .box {
        inline-size: 210px;
    }
}

@media screen and (max-width: 370px) {
    .box {
        inline-size: 190px;
    }
}

@media screen and (max-width: 350px) {
    .box {
        inline-size: 170px;
    }
}
</style>
