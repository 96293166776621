import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'LOCATION_UPDATE',
    smsDelete : 'LOCATION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {

        location:[],
        location_by_project:[],
        locationLoading: false,
        locationError: false,
        locationsubmitSuccess:false,

        ...store
    },
    
    mutations : {
        ...mutations,


        SET_LOCATION(state, location){
            state.location = location;
        
        },

        SET_LOCATION_BY_PROJECT(state, location){
            state.location_by_project = location;
        
        },
        LOCATION_LOADING (state, loading) {
            state.locationLoading = loading;
        },

        LOCATION_ERROR (state, loading) {
            state.locationError = loading;
        },

        LOCATION_SUBMIT_SUCCESS (state, success) {
            state.locationsubmitSuccess = success;
        }
    },

    actions: {
        //Location
        async addLocation({commit, dispatch}, payload) {
            commit('LOCATION_LOADING', true);
            commit('LOCATION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_location, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('LOCATION_SUBMIT_SUCCESS', true);
                dispatch ('fetchLocation')
                return response

            }catch (e) {
                commit('LOCATION_ERROR', true);
            }finally {
                commit('LOCATION_LOADING', false);
            }
        },

        async fetchLocation ({ commit },payload) {
            commit('LOCATION_LOADING', true);
            commit('LOCATION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_location, config);
                const results = result.data.data
                commit('SET_LOCATION',results);

            } catch(error) {
                commit('LOCATION_ERROR', true);
            }
            commit('LOCATION_LOADING', false);
        },

        
        async fetchLocationByProject ({ commit },payload) {
            commit('LOCATION_LOADING', true);
            commit('LOCATION_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_location_by_project + payload, config);
                const results = result.data.data
                commit('SET_LOCATION_BY_PROJECT',results);

            } catch(error) {
                commit('LOCATION_ERROR', true);
            }
            commit('LOCATION_LOADING', false);
        },

        async updateLocation({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.post(V1API.update_location+payload.id,payload.location,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchLocation');
                }
            )
            return response
        },

        async deleteLocation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_location + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchLocation');
            return response
        },


    },

    getters: {}
}
